<template>
  <div class="cloudsdaleRadio">
    <div class="cloudsdaleRadioApp" v-if="user">
      <v-app dark>
        <TheNavBar dark/>
        <v-main>
          <router-view />
        </v-main>
        <TheFooter dark/>
      </v-app>
    </div>
    <div class="cloudsdaleRadioOffline" v-else>
      <Offline />
    </div>
  </div>
</template>

<script>
import TheNavBar from "./components/PageComp/TheNavBar.vue";
import TheFooter from "./components/PageComp/TheFooter.vue";
import Offline from "./components/PageComp/Offline.vue";
export default {
  name: "App",
  components: {
    TheNavBar,
    TheFooter,
    Offline,
  },
  data() {
    return {
      background: "",
      user: null,
    };
  },
};
</script>

<style scoped>
#app {
  background-image: url("https://cdn.nishikino.xyz/cloudsdaleradio/CRbackground.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}
.imgNav {
  margin-right: 10px;
  border-radius: 25%;
  border-style: solid;
  border-width: 2px;
  border-color: aliceblue;
}
.nolink {
  text-decoration: none;
  color: ivory;
}
</style>