<template>
  <div class="TheFuckingNavBar">
        <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            alt="Cloudsdale Radio logo"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="100"
            src="https://cdn.nishikino.xyz/cloudsdaleradio/CRlogo.png"
            width="200"
            :to="{ name: 'Home' }"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>

      <div class="d-flex align-center">
        <router-link :to="{ name: 'Home'}"><v-btn text>Home</v-btn></router-link>
        <router-link :to="{ name: 'NavAudio'}"><v-btn text>Listen</v-btn></router-link>
        <router-link :to="{ name: 'Home'}"><v-btn text>Contact</v-btn></router-link>
        <router-link :to="{ name: 'About'}"><v-btn text>About</v-btn></router-link>
        <router-link :to="{ name: 'Request'}"><v-btn text>Request</v-btn></router-link>
        <router-link :to="{ name: 'API'}"><v-btn text>API</v-btn></router-link>
      </div>

      <v-spacer></v-spacer>

      <v-btn text>
        <!-- <span><v-icon>mdi-account</v-icon> {{ audit }}</span> -->
      </v-btn>
      <router-link :to="{ name: 'history' }">
        <v-btn text>
        <!-- <v-icon>mdi-play-circle-outline</v-icon> -->
        <div class="nowPlaying" v-if="!music.title">
        <span class="mr-2">Loading...</span>
        </div>
          <span> <img class="imgNav" :src="music.art" height="30px" /></span>
        <span class="mr-2">{{ music.artist }} - {{ music.title }}</span>
      </v-btn>
      </router-link>
    </v-app-bar>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TheNavBar",
  data: () => ({
    audit: 0,
    music: {
      artist: "",
      title: "",
      art: "",
    },
  }),
    methods: {
    getMusic() {
      axios
        .get(`${process.env.VUE_APP_PLAYER}/nowplaying`)
        .then((res) => {
          this.music.artist = res.data.artist;
          this.music.title = res.data.title;
          this.music.art = res.data.art;
        })
        .catch((err) => {
          console.log(err);
          this.music.artist = "Error";
          this.music.title = "API Error...";
        });
    },
    getAudit() {
      this.audit = 999;
      // axios.get(`${process.env.VUE_APP_BROADCASTER}/relays`)
      // .then((res) => {
      //   res.data.forEach((relay) => {
      //       this.audit = this.audit + relay.listeners;
      //   });
      // })
    },
  },
  mounted() {
    this.getMusic();
    this.getAudit();
    setInterval(() => {
      this.getMusic();
      // this.getAudit();
    }, 10000);
  },
}
</script>

<style scoped>
.imgNav {
  margin-right: 10px;
  border-radius: 25%;
  border-style: solid;
  border-width: 2px;
  border-color: aliceblue;
}
</style>