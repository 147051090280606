<template>
    <div class="history">
        <v-card>
            <v-card-text
            v-for="track in tracks"
            :key="track.id">
            <div class="track-info">
            <img height="50px" style="border-radius:5px;" :src="track.art" class="track-info-cover" :alt="track.id">
                <span class="track-info-title headline ml-4">
                    {{ track.title }} -
                </span>
                <span class="track-info-artist headline">
                    {{ track.artist }}
                </span>
                <span class="subline ml-2">
                    ({{ track.played_at }})
                </span>
            </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import axios from "axios"
import moment from "moment"
export default {
    name: "history",
    data() {
        return {
            tracks: []
        }
    },
    created() {
        axios.get("https://gateway.cloudsdaleradio.com/api/player/history")
        .then(response => {
            response.data.forEach(track => {
                this.tracks.push({
                    id: track.id,
                    title: track.title,
                    artist: track.artist,
                    art: track.art,
                    played_at: moment(track.played_at).format("HH:mm:ss"),
                    played_full: moment(track.played_ar).format("DD/MM/YYYY HH:mm:ss")
                })
                })
            })
        .catch(error => {
            console.log(error)
            this.tracks.push({
                id: "00000000000000",
                title: "Error",
                artist: "API might be down",
            })
        })
    }
}
</script>

<style scoped>
.history {
    padding: 20px;
}
.track-info {
    display: flex;
    align-items: center;
}
.track-info-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 10px;
}
.track-info-artist {
    font-size: 1rem;
}
</style>