<template>
    <div class="TheFuckingFooter">
          <v-footer
    color="primary lighten-1"
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="link in links"
        :key="link.name"
        color="white"
        text
        rounded
        class="my-2"
      >
        {{ link.name }}
      </v-btn>
      <v-card
      flat
      tile
      width="100%"
      class="primary lighten-2 text-center">
      <v-btn
      align="center"
      justify="center"
      v-for="icon in icons"
      :key="icon.name"
      :href="icon.link"
      class="mx-4"
      icon>
      <v-icon size="24px" disabled>{{ icon.name }}</v-icon>
      </v-btn>
      </v-card>
      <v-col
        class="primary lighten-2 py-4 text-center white--text"
        cols="12"
      >
        2013 - {{ new Date().getFullYear() }} — <strong>Cloudsdale Radio</strong> <br>
        <div class="foot_logo">
          <p>運営会社</p>
          <div class="figure">
        <img
        alt="SiriusHD"
        class="shrink mt-1"
        contain
        min-width="100"
        src="https://cdn.nishikino.xyz/siriushd/siriuslogo.webp"
        width="200">

          </div>
        </div>
      </v-col>
    </v-row>
  </v-footer>
    </div>
</template>

<script>
export default {
    name: "TheFooter",
data() {
    return {
    padless: true,
    variant: "default",
    links: [
        {
          name: 'Home'
        },
        {
          name: 'About Us'
        },
        {
          name: 'Team'
        },
        {
          name: 'Services'
        },
        {
          name: 'Blog'
        },
        {
          name: 'Contact Us'
        },
      ],
    icons: [
      {
        name: "mdi-twitter",
        link: "https://twitter.com/CloudsdaleRadio",
      },
      {
        name: "mdi-discord",
        link: "https://discord.gg/XZYZY",
      },
      {
        name: "mdi-facebook",
        link: "https://www.facebook.com/CloudsdaleRadio/",
      },
      {
        name: "mdi-instagram",
        link: "https://www.instagram.com/cloudsdale_radio/",
      },
      {
        name: "mdi-youtube",
        link: "https://www.youtube.com/channel/UCZ-_Z-_Z-_Z-_Z-_Z-_Z-",
      },
    ],
    }
},
  computed: {
    localAttrs() {
      const attrs = {};
      if (this.variant === "default") {
        attrs.absolute = false;
        attrs.fixed = false;
      } else {
        attrs[this.variant] = true;
      }
      return attrs;
    },
  },
}
</script>

<style scoped>
footer .foot_logo {
  display: table;
  max-width: 240px;
  width: 100%;
  margin: 0 auto 12px;
}
footer .foot_logo p {
  display: table-cell;
  width: 7em;
  vertical-align: middle;
  font-size:12px;
}
footer .foot_logo .figure {
  display: table-cell;
  width: calc(100% - 7em);
}
footer .foot_logo .figure img {
  width: 160px;
}

</style>