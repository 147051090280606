<template>
  <v-container>
    <div class="header">
      <img
        src="https://cdn.nishikino.xyz/cloudsdaleradio/CRlogo.png"
        width="50%"
      />
    </div>
    <div class="spacer"></div>
    <v-layout row wrap>
      <v-col cols="6">
        <v-card>
          <v-card-text>
            <Player />
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="6">
        <v-card flat tile text style="background-color: rgba(255,255,255, 0)">
          <v-btn
            align="center"
            justify="center"
            class="mx-4"
            @click="openPopup"
          >
          popup
          </v-btn>
          <v-btn
            align="center"
            justify="center"
            v-for="listen in listens"
            :key="listen.name"
            :to="listen.link"
            class="mx-4"
            
          >
          {{ listen.name }}
          </v-btn>
        </v-card>
      </v-col>
    </v-layout>
    <div class="spacer"></div>
    <div class="youtube text-center">
      <h3>YouTube Live</h3>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/5PgJt-RLhhQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </v-container>
</template>

<script>
import Player from "../components/Players/Players.vue"
export default {
  name: "Home",
  components: {
    Player,
  },
  data() {
    return {
      listens: [
        {
          name: "Navigateur",
          link: { name: "NavAudio"},
        },
        {
          name: "Request",
          link: { name: "Request"},
        },
        {
          name: "history",
          link: { name: "history"},
        },
      ]
    }
  },
  methods: {
    openPopup() {
      window.open(
        '/popup',
        '_blank',
        'width=400,height=670'
      );
    }
  },
};
</script>

<style>
/* center image in .header in the middle of the screen */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* spacer */
.spacer {
  height: 10vh;
}
</style>