import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Popup from '../views/popup.vue'
import Request from '../views/request.vue'
import NavAudio from '../views/NavAudio.vue'
import stationHistory from '../views/history.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/popup',
    name: 'Popup',
    component: Popup
  },
  {
    path: '/request',
    name: 'Request',
    component: Request
  },
  {
    path: '/nav/audio',
    name: 'NavAudio',
    component: NavAudio
  },
  {
    path: '/station/1/history',
    name: 'history',
    component: stationHistory
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
